<template>
  <h3 class="text-lg leading-6 font-medium text-gray-900">
    {{ $t('title') }}
  </h3>
  <p class="mt-1 text-sm text-gray-500">
    {{ $t('subtitle') }}
  </p>

  <div class="grid grid-cols-6 gap-4 mt-4">
    <div class="col-span-6">
      <BinaryQuestion v-model="answers[0]" :question="$t('questions.1')" />
    </div>

    <div class="col-span-6">
      <BinaryQuestion v-model="answers[1]" :question="$t('questions.2')" />
    </div>

    <div v-if="answers[1]" class="col-span-6">
      <BinaryQuestion v-model="answers[2]" :question="$t('questions.3')" />
    </div>

    <div class="col-span-6">
      <BinaryQuestion v-model="answers[3]" :question="$t('questions.4')" />
    </div>
  </div>

  <Alert class="mt-5">
    {{ $t('choice', { date: date(state.viewingRequest.start, 'dddd D MMMM'), from: date(state.viewingRequest.start, 'H:mm'), to: date(state.viewingRequest.end, 'H:mm') }) }}
  </Alert>

  <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
    <Btn icon-left="ArrowSmLeft" class="hidden sm:inline-flex" @click="back">
      {{ $t('back') }}
    </Btn>
    <Btn variant="primary" icon-left="Check" class="w-full" :disabled="loading" @click="confirm">
      {{ $t('confirm') }}
    </Btn>
  </div>
</template>

<script>
import state from '../../state'
import date from '../../util/date'
import BinaryQuestion from '@/components/ui/BinaryQuestion'

export default {
  components: { BinaryQuestion },
  setup () {
    return {
      state,
      date
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    contact () {
      return state.viewingRequest.contact
    },
    answers () {
      return state.viewingRequest.answers
    }
  },
  methods: {
    back () {
      this.$router.push({ name: 'info' })
    },
    confirm () {
      this.loading = true

      state.sendViewingRequest()
        .then(() => {
          this.$router.push({ name: 'confirm' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<i18n lang="yaml">
nl:
  title: 3. Vragen
  subtitle: Beantwoord de volgende vragen, zodat we weten waarmee we je nog verder
    kunnen helpen.
  questions:
    1: Kunnen we je ook helpen met hypotheekadvies?
    2: Ben je in het bezit van een eigen woning?
    3: Kunnen we je helpen met de verkoop van je eigen woning?
    4: Wil je op de hoogte gehouden worden van nieuw woningaanbod?
  choice: Je hebt gekozen voor {date} van {from} uur tot {to} uur.
  back: Terug
  confirm: Bevestigen
en:
  title: 3. Final questions
  subtitle: Please answer the following questions, so we know what else we can help
    you with.
  questions:
    1: Can we also assist you with mortgage advice?
    2: Do you currently own your own home?
    3: Can we help you sell your own home?
    4: Would you like to be kept informed of new housing offers?
  choice: You’ve selected {date} from {from} to {to}.
  back: Back
  confirm: Confirm
</i18n>
